import $ from 'jquery';
window.jQuery = window.$ = $;

// Match Height
import "jquery-match-height";

// Require Mmenu
import "mmenu-js/dist/mmenu";
import "mburger-webcomponent/dist/mburger/index.js";
//import "mmenu-js/dist/mmenu.polyfills";

// Get the Slider for the Hero
import "slick-carousel/slick/slick";

// Required for isotope to check if images have finished loading to run https://imagesloaded.desandro.com/
import "imagesloaded";

// Filter & sort magical layouts https://isotope.metafizzy.co/
// import "isotope-layout";

// Image gallery https://www.lightgalleryjs.com
import "lightgallery";
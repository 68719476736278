// Import jQuery and the Slider
import 'slick-carousel'

// Fire the Slider
$(function(){

    $('.slider-container').slick({
        infinite: true,
        autoplay: false,
        autoplaySpeed: 5000,
        dots: true,
        arrows: false,
        pauseOnHover: true

        
    });

    $('.herotwo-slider').slick({
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: true,
        lazyLoad: 'ondemand',
        pauseOnHover: true

    });
});

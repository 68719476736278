// ==========================================================================
// Nav
// ==========================================================================

let menu = document.getElementById('kirkwood-menu');
if( menu ){
        new Mmenu( "#kirkwood-menu", {
            "navbars": [
                {
                    "position": "bottom",
                    "content": [
                        "<a class='fa fa-envelope' href='mailto:info@kirkwood-homes.com'></a>",
                        "<a class='fa fa-phone' href='tel:01330833595'></a>",
                        "<a class='fab fa-facebook' href='https://www.facebook.com/KirkwoodHomesltd/'></a>",
                        "<a class='fab fa-instagram' href='https://www.instagram.com/kirkwoodhomes/'></a>"
                    ]
                }
            ],
            "extensions": [
                "position-right",
            ],
            "offCanvas": {
                "position": "right"
            },    
            "setSelected": {
                "hover": true
             }
            
        });
    }

